import { TicketItem } from "../Interfaces/TicketItem";

export interface StatsData {
    all: Stats;
    OIC: Stats;
    BMC: Stats;
    MECBot: Stats;
    VAChatbot: Stats;
    CSC: Stats;
    Unknown: Stats;
}

export interface Stats {
    totalAutomations: number;
    percentAutomated: number;
    duplicatesRemoved: number;

    totalRequests: number;
    requestsByType : {
        
        incident: number,
        sc_task : number,
        sc_request :number,
        problem :number
    }


    avgProcessingTime: number;


    requestsBySource: {
        Unknown: number;
        OIC: number;
        BMC: number;
        MECBot: number;
        VAChatbot: number;
        CSC: number;
    }
    // requestsByType: {
    //     incident: number;
    //     sc_task: number;
    //     sc_request : number;
    //     problem : number;
    // }
    requestsByAssignmentGroup: { [key: string]: number; }
    tags: {  [key: string]: number; }
    tagsBy:{ 
        incident    : {  [key: string]: number; },
        sc_task     : {  [key: string]: number; },
        sc_request  : {  [key: string]: number; },
        problem     : {  [key: string]: number; }
    }
    
    actions: {  [key: string]: number; }
    actionsBy:{ 
        incident    : {  [key: string]: number; },
        sc_task     : {  [key: string]: number; },
        sc_request  : {  [key: string]: number; },
        problem     : {  [key: string]: number; }
    }
}

const blankStats:Stats = {
    totalAutomations: 0,
    percentAutomated: 0,
    duplicatesRemoved: 0,

    totalRequests: 0,
    requestsByType : {
        
        incident: 0,
        sc_task : 0,
        sc_request :0,
        problem :0
    },
    avgProcessingTime: 0,
    requestsBySource: {
        Unknown: 0,
        OIC: 0,
        BMC: 0,
        MECBot: 0,
        VAChatbot: 0,
        CSC: 0,
    },
    requestsByAssignmentGroup: {},
    tags: {},
    tagsBy:{ 
        incident   : {},
        sc_task    : {},
        sc_request : {},
        problem    : {}
    },

    actions: {},
    actionsBy:{ 
        incident   : {},
        sc_task    : {},
        sc_request : {},
        problem    : {}
    }
};

function NameForAssignmentGroup(sys_id:string) {
    return sys_id; 
}
// function NameForTag(sys_id:string) {
//     return sys_id; 
// }

export class StatsService {
    static statsDataForTickets(items:TicketItem[]):StatsData {
        var stats = { 
            all: {...blankStats}, 
            OIC: {...blankStats},
             BMC: {...blankStats},
              MECBot: {...blankStats}, 
              VAChatbot: {...blankStats},
               CSC: {...blankStats} , 
               Unknown: {...blankStats} };

               stats =       JSON.parse(JSON.stringify(stats));
        
        var processingTime = { all: 0, OIC: 0, BMC: 0, MECBot: 0, VAChatbot: 0, CSC: 0 };
        var automated = { all: 0, OIC: 0, BMC: 0, MECBot: 0, VAChatbot: 0, CSC: 0 };

        items.forEach(item => {

            if (!stats[item.source]) {
                console.log("Missing Source", item.source);
                return;
            }
            
        

            let wasAutomated = false; 
            let numberOfAutomations = 0; 

            processingTime.all += item.processingTimeInMS || 0;
            processingTime[item.source] += item.processingTimeInMS || 0;

            if (item.isDuplicate) {
                stats.all.duplicatesRemoved++;
                stats[item.source].duplicatesRemoved++;
                wasAutomated = true; 
                numberOfAutomations++; 
            }


            stats.all.totalRequests += 1; 
            stats[item.source].totalRequests += 1; 
            
    
            console.log("stats.all.requestsByType[item.schema] BEFORE", item.schema, stats.all.requestsByType[item.schema], item.source); 

            stats.all.requestsByType[item.schema] = stats.all.requestsByType[item.schema]  + 1; 
            console.log("stats.all.requestsByType[item.schema] AFTER", item.schema, stats.all.requestsByType[item.schema], item.source); 

            stats[item.source].requestsByType[item.schema] = stats[item.source].requestsByType[item.schema] + 1; 

            if (item.schema === 'incident') {
                // console.log("debug", item.source); 
                console.log("incident", stats.all.totalRequests, stats.all.requestsByType[item.schema]);
            }


            stats.all.requestsBySource[item.source]++;
            stats[item.source].requestsBySource[item.source]++;

            if (item.assignment_group) {
                const agName = NameForAssignmentGroup(item.assignment_group);
                if (!stats.all.requestsByAssignmentGroup[agName]) { 
                    stats.all.requestsByAssignmentGroup[agName] = 0
                    stats[item.source].requestsByAssignmentGroup[agName] = 0; 
                }
                stats.all.requestsByAssignmentGroup[agName]++; 
                stats[item.source].requestsByAssignmentGroup[agName]++; 
            }

            if (item.tag) {
                if (!stats.all.tags[item.tag.label]) {
                    stats.all.tags[item.tag.label] = 0;
                    stats[item.source].tags[item.tag.label] = 0;
                }
                stats.all.tags[item.tag.label]++;
                stats[item.source].tags[item.tag.label]++;

                wasAutomated = true; 
                numberOfAutomations++; 
            }


            if (wasAutomated) {
                stats.all.totalAutomations++; 
                stats[item.source].totalAutomations++;
                automated.all++;
                automated[item.source]++;
            }
            
        })

        stats.all.avgProcessingTime = processingTime.all / stats.all.totalRequests;
        stats.OIC.avgProcessingTime = processingTime.OIC / stats.OIC.totalRequests;
        stats.BMC.avgProcessingTime = processingTime.BMC / stats.BMC.totalRequests;
        stats.MECBot.avgProcessingTime = processingTime.MECBot / stats.MECBot.totalRequests;
        stats.VAChatbot.avgProcessingTime = processingTime.VAChatbot / stats.VAChatbot.totalRequests;
        stats.CSC.avgProcessingTime = processingTime.CSC / stats.CSC.totalRequests;

        stats.all.percentAutomated = automated.all / stats.all.totalRequests;
        stats.OIC.percentAutomated = automated.OIC / stats.OIC.totalRequests;
        stats.BMC.percentAutomated = automated.BMC / stats.BMC.totalRequests;
        stats.MECBot.percentAutomated = automated.MECBot / stats.MECBot.totalRequests;
        stats.VAChatbot.percentAutomated = automated.VAChatbot / stats.VAChatbot.totalRequests;
        stats.CSC.percentAutomated = automated.CSC / stats.CSC.totalRequests;

        

        console.log("Returning Stats", stats);
        
        return stats;
    }
}



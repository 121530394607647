import { Store } from "pullstate";
import {User,Organisation, OrganisationMembership, MembershipRole, ProjectMembership, ProjectMembershipType, PermissionLevel, ProjectPermissionType, PermissionLevelValue } from '@swivl/great-grey-lib';
import APIService from "../Services/API.Service";


interface SessionModelStoreState {
    isUserLoaded:boolean;
    isLoggedIn:boolean;
    isLoggingIn:boolean;
    user?:User;
    token?:string;
    currentMembership?:OrganisationMembership
    currentProjectMembership?:ProjectMembership
}
export default class SessionModel { 
    static Store = new Store<SessionModelStoreState>({
        isUserLoaded:false,
        isLoggedIn:false, 
        isLoggingIn:false
      });
      static get state() {
        return SessionModel.Store.getRawState() 
      }
     static loadCurrentUserAndNotify(){
        var session;
        try {
          const serializedSession = localStorage.getItem('session');
          
          if (serializedSession ) {
            session = JSON.parse(serializedSession);
            console.log("Got Session",session );
    
          }
    
        } catch (err) {
          throw Error("Error Loading From Local Storage");
        }
    
        SessionModel.Store.update(s => {
            s.isUserLoaded = true;
            s.isLoggedIn = (session && session.user && session.token)? true: false;
            s.user = (session && session.user)?session.user : undefined;
            s.token = (session && session.token)?session.token : undefined;
            s.currentMembership  = (session && session.currentMembership)?session.currentMembership : undefined
            s.currentProjectMembership = (session && session.currentProjectMembership)?session.currentProjectMembership : undefined

        })
    
      
    
    }
   static async login(email:string, password:string):Promise<any> {
      console.log("Login", email, password)
        try {
          const response = await APIService.login(email.toLowerCase(),password);
          console.log("response",response)

          return SessionModel.userLoggedIn(response);
        } catch(e) {
          return Promise.reject(e);
        }
  }

  static async userLoggedIn(session:any):Promise<any> {
    if (!session.currentMembership && session.user && session.user.membership && session.user.membership.length) {
      session.currentMembership = session.user.membership[0];
    }
    // let currentProjectMembership; 
    if (session.currentMembership && session.currentMembership.projectMemberships && session.currentMembership.projectMemberships.length)  {
      session.currentProjectMembership = session.currentMembership.projectMemberships[0]
    }

    const serializedState = JSON.stringify(session);
    localStorage.setItem('session', serializedState);
  
    SessionModel.Store.update(s => {
      s.isUserLoaded = true;
      s.isLoggedIn = false; 
      s.isLoggedIn = true

      s.user = session.user;
      s.token = session.token;
      s.currentMembership  = session.currentMembership
      s.currentProjectMembership = session.currentProjectMembership

  })


  
    // Actions.Controller.trigger({type:ActionType.UserLoggedIn, user:this.state.user});
    // if (session && session.currentMembership && session.currentMembership.role && Model.Session.state.currentMembership.role === MembershipRole.Admin) {
    //   Model.Navigation.setPath('/dashboard');
    // } else {
    //   Model.Navigation.setPath('/task');

    // }
    console.log("howdy",session )
    return Promise.resolve(true);
}


  static async logOut() {

  }
  

    }
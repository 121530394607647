import React from 'react';
import "./Box.scss"

export const BoxRow: React.FC = (props) => {
    return <div className="boxRow">{props.children}</div>
}
export const Box = (props:{value:string|number, label:string }) => {
    return <div className="box">
    <div className="boxNumber">
    {typeof props.value === 'number' ? props.value.toLocaleString() :  props.value}
    </div>
    {props.label}
  </div>
}
  
import { Store } from "pullstate";
import { Config } from "../Config/Config";
import SessionModel from "./Session.Model";
import {DateTime} from 'luxon'
import { StatsData, StatsService } from "../Services/Stats.Service";
type DateRange = {startDate: Date, endDate: Date}

interface TicketItem {
    id: string;
}



interface TicketItemsModelState {
    isLoading: boolean;
    searchRange:DateRange;
    ticketItems?:TicketItem[];
    stats?:StatsData
}
    
export default class TicketItemsModel { 
    static Store = new Store<TicketItemsModelState>({
        isLoading: true,
        searchRange:{startDate:DateTime.now().minus({'month':1}).toJSDate(), endDate:new Date()}
    });

    static get state() { return TicketItemsModel.Store.getRawState() }
    static setSearchRange(isStart:boolean, date:Date) {
        console.log("setSearchRange", isStart, date);
        
        const searchRange = (isStart) ? {startDate:date , endDate:TicketItemsModel.state.searchRange.endDate} : {startDate: TicketItemsModel.state.searchRange.startDate, endDate:date}
            TicketItemsModel.Store.update(s => {
                s.searchRange = searchRange    })
       
    }
    static loadTicketItems(startDate:Date, endDate:Date) {
        TicketItemsModel.Store.update(s => {
            s.isLoading = true; 
        })
        fetch(`https://${Config.ticketingServerAddress}/api/v1/ticket/all/prod?startDate=${DateTime.fromJSDate(startDate).toISO()}&endDate=${DateTime.fromJSDate(endDate).toISO()}`, {
                method: 'GET',
                headers: { "token" : SessionModel.state.token } as any
           }).then(response => response.json())
           .then(data => {
                console.log("Loaded", data);
                TicketItemsModel.Store.update(s => {
                    s.stats = StatsService.statsDataForTickets(data)
                    s.isLoading = false 
                })
            })
    }

  

    }
import { Store } from "pullstate";
import React from "react";

export enum NavBarComponent {
  Logo = "Logo",

}

interface NavBarStoreState {
    left?:React.ReactNode | NavBarComponent;
    center?:React.ReactNode | NavBarComponent;
    right?:React.ReactNode | NavBarComponent;
}
export class NavBar { 
    static Store = new Store<NavBarStoreState>({});
    static get state() {  return NavBar.Store.getRawState() }
   
    static nodeFor(component:NavBarComponent) { 
      switch (component) {
        case NavBarComponent.Logo:
          return <img src="/swivl.png" alt="swivl" />
        default:
          return null;
      }
    }
    
  static async set(left?:React.ReactNode, center?:React.ReactNode, right?:React.ReactNode):Promise<any> {    
    NavBar.Store.update(s => {
      s.left   = (left   && Object.values(NavBarComponent).includes(left as any))   ? NavBar.nodeFor(left as any) : left;
      s.center = (center && Object.values(NavBarComponent).includes(center as any)) ? NavBar.nodeFor(center as any) : center;
      s.right  = (right  && Object.values(NavBarComponent).includes(right as any))  ? NavBar.nodeFor(right as any) : right;; 
  })
}

}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";


// Parse.initialize("MKT-Server-Local", "ZakaqpiufBBWfeperq6Kbfzz");
// (Parse as any).serverURL = 'https://mktserver.ngrok.io/parse'



import "@swivl/great-grey-components/build/index.css"; // This must be loaded first to overwrite bulma.
import { Config } from './Config/Config';

if (process.env.NODE_ENV === 'production')  {
    Config.serverAddress = 'great-grey-server.herokuapp.com';
    Config.serverURL = 'https://' + Config.serverAddress;
    Config.serverName = 'Great-Grey-Server-Local';
    Config.fileServerURL = 'https://great-grey-local.s3.amazonaws.com';
    Config.ticketingServerAddress = "ticketing.swivl.ai";
} else {
  Config.serverAddress = 'great-grey-server.herokuapp.com';

    // Config.serverAddress = 'greatgreyserver.ngrok.io';
    Config.serverURL = 'https://' + Config.serverAddress;
    Config.serverName = 'Great-Grey-Server-Local';
    Config.fileServerURL = 'https://great-grey-local.s3.amazonaws.com';
    Config.ticketingServerAddress = "great-grey-ticketing.ngrok.io";
}



ReactDOM.render(
  <React.StrictMode>
<BrowserRouter>  <App /></BrowserRouter>
  
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Prediction } from "@azure/cognitiveservices-luis-runtime/esm/models";
import { CoxRequest } from "./Cox.Models";
// import { CoxRequest, EnvironmentType, SchemaType, SourceType } from 
export  enum SchemaType {
    incident = "incident",
    sc_task = "sc_task",
    sc_request = "sc_request",
    problem = "problem"
    }
   export enum EnvironmentType {
     Internal="internal",
      Local = "local",
      Test = "test",
      Dev = "dev",
      QA = "qa", 
      Prod = "prod"
  
    }
    export enum SourceType { 
      Unknown = "Unknown",
      OIC = "OIC",
      BMC = "BMC",
      MECBot = "MECBot",
      VAChatbot = "VAChatbot",
      CSC = "CSC"
    }
  

export enum TicketItemStatus {
    Processing  = "Processing",
    Handled     = "Handled",
    Failed      = "Failed" 
}
export interface ServiceNowTagPayload {
    title:string 
    table:string 
    label:string
    table_key:string
}


export interface TicketItem {
    id: string;
    createdAt:Date;
    updatedAt:Date; 
    status:TicketItemStatus; 

    isDummy:boolean;

    environment:EnvironmentType;
    source: SourceType; 
    schema:SchemaType

    impact:number, 
    urgency:number,
    priority:number,

    state: number;

    incident_number?:string; // Service Now ID
    sys_id?:string; // Service Now ID
    assignment_group?:string

    organisationId:string;
    projectId:string; 
    request:CoxRequest

    history:any

    changes:any
    tag?:ServiceNowTagPayload
    shortDescIntent?:string 
    shortDescScore?:number 
    descIntent?:string 
    descScore?:number 
    shortDescPrediction?:Prediction
    descPrediction?:Prediction


    isDuplicate?:boolean
    duplicateParentId?:string
    isParent?:boolean
    processingTimeInMS?:number

}
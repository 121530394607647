import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactJson from 'react-json-view'
import SessionModel from '../../../Models/Session.Model';
import { Config } from '../../../Config/Config';

export const TicketDetailsView:React.FC = () => {
    let { ticketId } = useParams();
    const [state, setState] = React.useState<any>() 
        useEffect(() => {
           if (!SessionModel.state.token ) {
               alert("Please log in first");
               return; 
           }


           
           fetch(`https://${Config.ticketingServerAddress}/api/v1/ticket/${ticketId}`, {
                method: 'GET',
                headers: { "token" : SessionModel.state.token } 
           }).then(response => response.json())
           .then(data => {
            setState(data);
        })

        }, [ticketId])

    console.log("ticketId", ticketId);
    return <div style={{padding:"40px"}}>

       {state ?  <ReactJson collapsed={false} src={state} /> : null}

    </div>

}
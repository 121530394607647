import { Select } from '@swivl/great-grey-components';
import React, { useEffect } from 'react';
import ReactJson from 'react-json-view';
import { Box, BoxRow } from '../../Components/Box/Box';
import { Config } from '../../Config/Config';
import { TicketItem } from '../../Interfaces/TicketItem';
import { NavBar } from '../../Models/NavBar.Model';
import SessionModel from '../../Models/Session.Model';
import "./SimulationView.scss"
interface ItemToSimulate {
    sys_id:string 
    delayBeforeRunning:number 
}
enum TagIs {
    Set = "Set", 
    Valid = "Valid",
    Invalid = "Invalid",
    NotSet = "NotSet"
}

interface ValidationResults {
    PERCENT_TAGGED:string
    TOTAL:string   
    VALIDATED:string
    PERCENT:string
    INVALID:string
    NOT_SET:string  
}

function validationStatusForTicketItem(data:TicketItem)  {

    let status = TagIs.NotSet
    if (data.request.validatedTag) {
        console.log("^^ validatedTag", data.request.validatedTag);
        
        if (data && data.tag) {
            console.log("^^ tag", data.tag);
            if (data.tag.title === data.request.validatedTag) {
                status = TagIs.Valid
            } else {
                status = TagIs.Invalid
            }
        } else { 
            status = TagIs.Invalid
        }
    }
    const tagStatus =  (data && data.tag && data.tag.title) ? TagIs.Set : TagIs.NotSet

    return {validatonStatus: status, tagStatus: tagStatus}
}

interface TagStatistic {
    validatonStatus:TagIs, tagStatus:TagIs
}

export const SimulationView:React.FC = () => { 
    const [idsToSimulate, setIdsToSimulate] = React.useState<ItemToSimulate[]>([
 

    ]);
    const [runningIndex, setRunningIndex] = React.useState<number>(-1);

    const [textAreaText, setTextAreaText] = React.useState<string>(window.localStorage.getItem("simulationIds") || "");

    const [tagStatistics, setTagStatistics] = React.useState<{[index:number]:TagStatistic }>({});

    const [validationResults, setValidationResults] = React.useState<ValidationResults>(   {
        PERCENT_TAGGED: "--",
        TOTAL:"--",   
        VALIDATED:"--",
        PERCENT:"--%",
        INVALID:"--",
        NOT_SET:"--"
    } );
    const [modelAppId, setModelAppId] = React.useState<string>(localStorage.getItem("modelAppId") || "default");

    useEffect(()=> { 
        NavBar.set( null, <h1>Simulation</h1>, null);
        
    } )

    const start = () => {


        fetch(`https://${Config.ticketingServerAddress}/api/v1/simulation/clear`, {
            method: 'GET',
            headers: { "token" : SessionModel.state.token }  as any 
       }).then(response => response.json())
       .then(data => { 
            console.log("Cleared", data);
            setRunningIndex(0)
       })
    }

    const updateValidatedStatistics = React.useCallback((index:number,  status:{validatonStatus:TagIs, tagStatus:TagIs}) => {

        // let status = TagIs.NotSet
        // if (data.request.validatedTag) {
        //     console.log("^^ validatedTag", data.request.validatedTag);
            
        //     if (data && data.tag) {
        //         console.log("^^ tag", data.tag);
        //         if (data.tag.title === data.request.validatedTag) {
        //             status = TagIs.Valid
        //         } else {
        //             status = TagIs.Invalid
        //         }
        //     } else { 
        //         status = TagIs.Invalid
        //     }
        // }
        let newValidatedStatistics = {...tagStatistics}
        newValidatedStatistics[index] = status
        console.log("^^ updateValidatedStatistics", index, status, tagStatistics, newValidatedStatistics)

        setTagStatistics(newValidatedStatistics)
    }, [tagStatistics]); 

    useEffect(()=> {
        console.log("validatedStatistics",tagStatistics)
        const values = Object.values(tagStatistics);


        const percentTagged = values.filter(x => x.tagStatus === TagIs.Set).length;

        const valid = values.filter(x => x.validatonStatus === TagIs.Valid).length;
        const invalid = values.filter(x => x.validatonStatus === TagIs.Invalid).length;
        const notSet = values.filter(x => x.validatonStatus === TagIs.NotSet).length;
        const totalOfSet = valid + invalid;
        const total = values.length;


        console.log("***", percentTagged, "/",total)
        let validationResults = {
            PERCENT_TAGGED: (total && percentTagged ? (percentTagged / total * 100).toFixed(0) + "%" : "0%"),
            TOTAL:String(total),   
            VALIDATED:String(valid),
            PERCENT:valid && totalOfSet ? (valid / totalOfSet * 100).toFixed(0) + "%" : "0%",
            INVALID:String(invalid),
            NOT_SET:String(notSet)
        }
    
        



        setValidationResults(validationResults)
    }, [tagStatistics])

    return <div className="SimulationView">
        {idsToSimulate.length === 0 ? <div> 
            <label>MODEL</label>
            <Select value={modelAppId} onChange={(option) => { 
                localStorage.setItem("modelAppId", option.value);
                setModelAppId(option.value)}} options={[
      { value: "default", label: 'DEFAULT MODEL' },
      { value: "1c1166b4-152d-4fcd-874b-45f33420abae", label: 'STAGING MODEL - 1c1166b4-152d-4fcd-874b-45f33420abae' },   
      ]} />

      <br /> 
            <label>SYS IDs TO SIMULATE</label>
            <textarea value={textAreaText} style={{width:"100%", height:"100%"}} onChange={(e)=> { setTextAreaText(e.target.value) }}/>
            <button onClick={()=> { 
                window.localStorage.setItem("simulationIds", textAreaText)
                let itemsArray = textAreaText.split(",")

                let items:ItemToSimulate[] 
                items = itemsArray.map(item => ({sys_id: item.trim(), delayBeforeRunning: 1}))
                setIdsToSimulate(items)
                setTimeout(()=> { start() }, 500);
            }}>START</button> 
            
            </div> : null}

            {/* {runningIndex < 0 && idsToSimulate.length? <button onClick={start}>Start</button> : null} */}

        {idsToSimulate.length > 0 ? <div> 
            <BoxRow>

            <Box label='% TAGGED'       value={validationResults.PERCENT_TAGGED}             />

        <Box label='TOTAL'       value={validationResults.TOTAL}             />
        <Box label='VALIDATED'      value={validationResults.VALIDATED} />
        <Box label='% VALIDATED'            value={validationResults.PERCENT}   />
        <Box label='INVALID'              value={validationResults.INVALID}    />
        <Box label='NOT SET'             value={validationResults.NOT_SET}    />
      </BoxRow>


            {idsToSimulate.map((item, index)=> <SimulationItem key={index} item={item} index={index} runningIndex={runningIndex} setRunningIndex={setRunningIndex} updateValidatedStatistics={updateValidatedStatistics} modelAppId={modelAppId}/>)} </div> : null}
        </div>
}

interface ItemProps {
    item:ItemToSimulate, 
    index:number, 
    runningIndex:number, 
    setRunningIndex:(number)=>void
    modelAppId:string 
    updateValidatedStatistics(index:number, status:{validatonStatus:TagIs, tagStatus:TagIs}):void
}

const SimulationItem:React.FC<ItemProps> = ({item, modelAppId, index, runningIndex, setRunningIndex, updateValidatedStatistics}) => {
    const [ticketItem, setTicketItem] = React.useState<TicketItem | undefined>(undefined);
    const [hasStarted, setHasStarted] = React.useState(false);
    const [status, setStatus] = React.useState<TagIs>(TagIs.NotSet);
    useEffect(()=> {
        if (!hasStarted && index === runningIndex) {
            setHasStarted(true);
            console.log("Starting", item.sys_id);
            
            setTimeout(()=> {
                // setRunningIndex( index + 1);
                const modelId = modelAppId.length && modelAppId !== "default" ? `?customLUISAppId=${modelAppId}` : "";
                console.log("^^ modelId", modelId);


                fetch(`https://${Config.ticketingServerAddress}/api/v1/simulation/simulate/${item.sys_id}${modelId}`, {
                    method: 'GET',
                    headers: { "token" : SessionModel.state.token }  as any 
               }).then(response => response.json())
               .then(data => {
                console.log("GOT DATA", data)
                const status = validationStatusForTicketItem(data)
                setStatus(status.validatonStatus); 
                updateValidatedStatistics(index, status);
                setTicketItem(data);
                setRunningIndex( index + 1);
            })

            }, item.delayBeforeRunning * 1000)
        }
    }, [runningIndex, hasStarted, modelAppId, index, setRunningIndex, item.delayBeforeRunning, updateValidatedStatistics, item.sys_id])

    let _status = "NOT STARTED"; 
    if (runningIndex === index) {
        _status = "RUNNING";
    } else if (runningIndex > index) {
        _status = "FINISHED";
    }


    const data = (ticketItem as any)?.request?.data as any;
    console.log(data);


    return <div className={status ===TagIs.Invalid ?  "SimulationItem isInvalid" : "SimulationItem"}> 
        <h1>SYS ID: {item.sys_id}</h1>
        <div className="delayRow">Delay before running: {item.delayBeforeRunning}</div>
        <div className="status">STATUS <b>{_status}</b></div>
   
        {ticketItem ? 
           
        <div className="ticketItemChanges">
            <div className="row"><b>Ticket Item:</b> <ReactJson collapsed={true} src={ticketItem} />  </div> 

            <div className="row"><b>Changes:</b>  <ReactJson collapsed={false} src={ticketItem.changes} />  </div> 
            <div className="row"><b>Tag: </b>  <ReactJson collapsed={false} src={ticketItem.tag || {}} /> </div> 
            <div className="row"><b>Validated Tag: </b>  <ReactJson collapsed={false} src={{status: status, tag:ticketItem.request?.validatedTag}} /> </div> 

            <div className="row"><b>History:</b>  <ReactJson collapsed={true} src={ticketItem.history} />  </div> 

            <div className="row"><b>Is Duplicate:</b>  {ticketItem.isDuplicate} {ticketItem.duplicateParentId}  </div> 
            <div className="row"><b>Descriptions:</b> <ReactJson collapsed={false} src={
                { 
                    short_description:data?.short_description,
                    description:data?.description
                }

            } />   </div> 


        </div> 
        : null}
    </div>
}
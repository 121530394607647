import React from 'react';
import "./LoadingView.scss";


export default class LoadingView extends React.Component {
  render() {
      return <div className="LoadingView">
        <div className="loadingContent">
        <div className="loadingBar">LOADING</div>
        </div>
      </div> 
       
  }

}

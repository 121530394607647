import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './App.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  Navigate,
  Outlet
} from "react-router-dom";
import HomeView from '../Views/Home/HomeView';
import { LoginView } from '../Views/Login/LoginView';
import { TicketDetailsView } from '../Views/Tickets/Details/TicketDetailsView';
import SessionModel from '../Models/Session.Model';
import { NavBarLayout } from './NavBar/NavBarView';
import { SimulationView } from '../Views/Simulation/SimulationView';






const App:React.FC<any> = () => {


  const {token, isLoggedIn, isUserLoaded} = SessionModel.Store.useState(s => s)

  useEffect(() => {
    SessionModel.loadCurrentUserAndNotify()
  }, [])
  if (!isUserLoaded) {
    return <div style={{textAlign:"center"}}>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  }
  // React.useEffect(() => { 

  // React.useEffect(() => { 
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const params = Object.fromEntries(urlSearchParams.entries());
  //   if (params["token"]) {
  //     (Config as any).TOKEN = params["token"]; 

  //     localStorage.setItem("token", params["token"]);

  //     Model.load()

  //     return; 
  //   }
  //   if (localStorage.getItem("token")) {
  //     (Config as any).TOKEN = localStorage.getItem("token"); 
  //   } else {
  //     const results = prompt("What's the password jack?");
  //     if (results) {
  //     localStorage.setItem("token", results);
  //     (Config as any).TOKEN = results; 

  //     }
  //   }
  //   if (Config.TOKEN) {
  //     Model.load()
  //   } else {
  //     alert("NA NA NA can't do that.")
  //   }
  // }, [])

  if (!isUserLoaded) {
    return <div style={{textAlign:"center"}}>Loading</div> 
  }

  
  if (!isLoggedIn) {


    return        <Routes>
  
    <Route path="/login" element={<LoginView />} />
    <Route path="/*" element={<Navigate to="/login" />} />

    </Routes>
  
  }



  return    <Routes>
            <Route element={<NavBarLayout />}>
            <Route path="/" element={<HomeView />} />
            <Route path="/simulation" element={<SimulationView />} />
            <Route path="/login" element={<Navigate to="/" />} />
            <Route path="/ticket/:ticketId" element={<TicketDetailsView />} />
  </Route>
  </Routes>


}



export default App; 
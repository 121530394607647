import React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BarAndPieChart } from '@swivl/swivl-components';

import "./BarAndPieBox.scss"
import { BarAndPieChartData } from '@swivl/swivl-components/build/BarAndPieChart/BarAndPieChart';

interface BarAndPieBoxProps {
    title:string; 
    data:BarAndPieChartData[] 
}



export const BarAndPieBox = (props: BarAndPieBoxProps) => {
        const {data, title} = props; 
       
        
        return <div className="BarAndPieBox ">
             <div className="chartHeader">  
             <div className="chartHeaderTitle">  <span>{title}</span>                </div>
                <div className="spacer" /> 
                <div className="headerTotal"></div>
               </div>
            <div className="chartContent">
            <div className="barAndPieWapper">
                        <BarAndPieChart data={data} showTotals={true}  width={580} height={200} />
            </div>
                        </div>
                        <a className="viewAll">VIEW ALL</a> 
        </div>
    
}


import { ButtonWithOptions, Color, HorizontalBarGraph, Size } from '@swivl/swivl-components';
import { HorizontalBarGraphData } from '@swivl/swivl-components/build/HorizontalBarGraph/HorizontalBarGraph';
import React from 'react';




import "./BarAndPieBox.scss"


interface BarAndPieBoxProps {
    title:string; 
    tags: {  [key: string]: number; },
    tagsBy:{ 
        incident    : {  [key: string]: number; },
        sc_task     : {  [key: string]: number; },
        sc_request  : {  [key: string]: number; },
        problem     : {  [key: string]: number; }
    }
} 

const tagsToHorizontalBarGraphData = (tags: {  [key: string]: number; }): HorizontalBarGraphData[] => {
    const colors =  ["#00B9CA" , "#1DD8CD" , "#E92200", "#F67902", "#FFA900" ,"#E9E3DF"]



    let array =  Object.keys(tags ).map((key, index) => ({
    title:key ,
    rows:[ {value:tags[key] ,   color:"#A034F0"}],
    total:tags[key] 
       }))

    array.sort((a,b) => b.total - a.total)
    let newArray:any = []
    for (let index = 0; index < array.length && index < 5; index++) {
        const element = array[index];
        element.rows[0].color = colors[index]
        newArray.push(element)
    }   
    
    return newArray;

}


export const HorizontalGraphBox = (props: BarAndPieBoxProps) => {
        const {tags, title} = props; 
        let content, viewAll; 
        const [filter , setFilter] = React.useState("All");

        const data = tagsToHorizontalBarGraphData(tags)

        // if (questData) {   
        //     if (!questData.total) {
        //         // total = ""
        //         content = <div className="loadingContent ">{}</div>
        //     } else {
        //     // const data = QuestData(questData)
        //     // total = questData.total.toLocaleString()
            content = <div className="barAndPieWapper">
                                 <HorizontalBarGraph data={data} showTotals={true} />
            </div>
            viewAll = <a className="viewAll">VIEW ALL</a>
        //     }
        // } else {
        //     content = <div className="loadingContent"><FontAwesomeIcon icon={faSpinner} spin /></div> 
        // }

        const menuSelected = (e) => {            
            setFilter(e.target.name)
        }
        return <div className="BarAndPieBox ">
            <div className="chartHeader">  
            <div className="chartHeaderTitle">  <span>{title}</span>   
            <ButtonWithOptions  
                size={Size.Small}  
                color={Color.Light}  
                buttonOptions={[ 
                      { title:"All", name:"All", onClick:menuSelected }, 
                      { title:"Incidents", name:"Incidents", onClick:menuSelected },  
                      { title:"Catalog Request", name:"Catalog Request", onClick:menuSelected },
                      { title:"SC Task", name:"SC Task", onClick:menuSelected },
                      { title:"Problem", name:"Problem", onClick:menuSelected },
                    ]
                      }>{filter}</ButtonWithOptions>
            
                         </div>
                <div className="spacer" /> 
                <div className="headerTotal"></div>
                </div>
            <div className="chartContent">
                {content}
            </div>
            {viewAll}  
        </div>
    
}
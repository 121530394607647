import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {DateRangeSelect} from "@swivl/swivl-components";
import './HomeView.scss';
import '@swivl/swivl-components/build/bundle.css'
import { NavBar, NavBarComponent } from '../../Models/NavBar.Model';
import TicketItemsModel from '../../Models/TicketItems.Model';
import LoadingView from '../../Components/Loading/LoadingView';
import { Select } from '@swivl/great-grey-components';
import { SourceType } from '../../Interfaces/TicketItem';
import { BarAndPieBox } from './Components/BarAndPieBox';
import { HorizontalGraphBox } from './Components/HorizontalGraphBox';
import { StackedBarGraphBox } from './Components/StackedBarGraphBox';
import { Box, BoxRow } from '../../Components/Box/Box';


const TagMap = {
"1b7b0739db982810a142a5730596196b":"Application Performance issues",     
"7e6e9d3b1baa6090853c5460604bcb6c":"CEIEBS DB Blocking Sessions Issue",                        
"3a9796731be2a090853c5460604bcba8":"CEIEBS Db Swap Space Issue",                       
"61a136eddb58e410a142a573059619c6":"CTDI/WWT Integrations",              
"90c38d0713f80b00610650622244b0c3":"Oracle Financials Access Issues",    
"1ebcd7a11b26ac10853c5460604bcb0f":"Supplier portal Login Issues",       
"a2cf263ddb182810a142a57305961987":"Update ICOMS to GL Mapping",                       
"8d113a7ddb182810a142a5730596190e":"Validate GL String",                   
"0a448b31db982810a142a573059619e2":"Integration/Interface issue",        
"77688a8d1b6a28103f0b8402604bcb71":"Grant Responsibilities",             
"74080da91bfae010853c5460604bcbd4":"Supplier Portal Registration Issue", 
"d3f6b2f1db582810a142a5730596196b":"Unable to submit an expense report", 
"ea464d651bfae010853c5460604bcb17":"Supplier Portal Invoice Issue",      
"cb9ca3e11bcb2490853c5460604bcb46":"Clear Cache/Browser issue",          
"c2d496271b2eac503f0b8402604bcbcd":"Supplier Portal Bank Setup",         
}





const HomeView = () => {
  const [source, setSouce] = useState("all")
  // const [searchRange, setSearchRange] = useState({startDate: new Date(), endDate: new Date()})

  const {isLoading, stats, searchRange} = TicketItemsModel.Store.useState(s => s)



  useEffect(()=> {
    NavBar.set(   
       <DateRangeSelect startDate={searchRange.startDate} endDate={searchRange.endDate} datePicked={TicketItemsModel.setSearchRange} />,
       NavBarComponent.Logo, 
       null);

       TicketItemsModel.loadTicketItems(searchRange.startDate, searchRange.endDate);
      }, [searchRange])


  if (isLoading || !stats) {
    return <LoadingView />
  }

  let _stats = stats.all; 
  if (source === SourceType.OIC)       { _stats = stats.OIC }
  if (source === SourceType.BMC)       { _stats = stats.BMC }
  if (source === SourceType.MECBot)    { _stats = stats.MECBot }
  if (source === SourceType.VAChatbot) { _stats = stats.VAChatbot }
  if (source === SourceType.CSC)       { _stats = stats.CSC }
  


  let tags = {}
  for (const key in _stats.tags) {
    if (Object.prototype.hasOwnProperty.call(_stats.tags, key)) {
      const element = _stats.tags[key];
      let newKey = TagMap[key] || key;
      tags[newKey] = element;
    }
  }

  console.log(_stats)

  return <div className="HomeView">
  <div className="row">
    <div style={{width: "190px"}}>
  <label>SOURCE</label>    
    <Select value={source} onChange={(option) => { setSouce(option.value)}} options={[
      { value: "all", label: 'ALL' },
      { value: SourceType.OIC, label: 'OIC' },   
      { value: SourceType.BMC, label: 'BMC' },   
      { value: SourceType.MECBot, label: 'MECBot' },   
      { value: SourceType.VAChatbot, label: 'VAChatbot' },   
      { value: SourceType.CSC, label: 'CSC' },   
      ]} />
      </div>
  </div>

  <BoxRow>
  <Box label='TOTAL AUTOMATIONS' value={_stats.totalAutomations} />
  <Box label='AUTOMATIONS AS %' value={Math.ceil(_stats.percentAutomated * 100) + "%"} />
  <Box label='DUPLICATES REMOVED' value={_stats.duplicatesRemoved} />
      </BoxRow>

      <BoxRow>
        <Box label='TOTAL REQUESTS'       value={_stats.totalRequests}             />
        <Box label='CATALOG REQUEST'      value={_stats.requestsByType.sc_request} />
        <Box label='INCIDENTS'            value={_stats.requestsByType.incident}   />
        <Box label='SC TASK'              value={_stats.requestsByType.sc_task}    />
        <Box label='PROBLEMS'             value={_stats.requestsByType.problem}    />
        <Box label='AVG. PROCESSING TIME' value={Math.ceil(_stats.avgProcessingTime) + "ms"}         />
      </BoxRow>

      <div className="row">
        <BarAndPieBox title="Requests by Source" data={[
          { title:"OIC",                           value: _stats.requestsBySource.OIC       },
          { title:"BMC / New Relic Evanios Agent", value: _stats.requestsBySource.BMC       },
          { title:"MEC BOTs",                      value: _stats.requestsBySource.MECBot    },
          { title:"VA Chatbot",                    value: _stats.requestsBySource.VAChatbot },
          { title:"User Input from CSC Channels",  value: _stats.requestsBySource.CSC       },
          ]} />

        <BarAndPieBox title="Requests by Type" data={[
          { title:"Incidents",       value: _stats.requestsByType.incident   },
          { title:"Catalog Request", value: _stats.requestsByType.sc_request },
          { title:"SC Task",         value: _stats.requestsByType.sc_task    },
          { title:"Problems",        value: _stats.requestsByType.problem    },  
          ]} />
      </div>

      <div className="row">
        <BarAndPieBox title="Requests by Assignment Group" data={
          Object.keys(_stats.requestsByAssignmentGroup ).map((key, index) => ({
           title: key,   value: _stats.requestsByAssignmentGroup[key] 
          }))
          } />

  <HorizontalGraphBox 
        title="Tags by"
        tags={tags}
        tagsBy={_stats.tagsBy}  
    />
      </div>


{/* <div className="row">
<StackedBarGraphBox 
  title="Actions Taken on" 
  actions={_stats.actions}
  actionsBy={_stats.actionsBy}

  

/>
</div> */}
      </div>;
}
export default HomeView; 


// export default class App extends React.Component <any,AppState> {
// constructor(props:any) {
//   super(props) 
// }
// componentDidMount() {

// }

// render() {

//   return <div>HI</div>;
  
  
// }
// }
